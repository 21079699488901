





























































































































































import FormatDate from '@/helpers/FormatDate'
import CommonTopicService from '@/services/CommonTopicService'
import GroupTopicService from '@/services/GroupTopicService'
import { Prop, Component, Vue } from 'vue-property-decorator'
import { TopicCompactViewModel } from './Layout/NewFeedLayout.vue'
import { getCommonTopicCount } from '@/helpers/GetNotificationCount'
import PreviewText from '@/helpers/PreviewText'
//@ts-ignore
import _ from 'lodash'

import { getModule } from 'vuex-module-decorators'
import CommonTopic from '@/store/modules/CommonTopic'
import store from '@/store'
import { EUserStatus } from '@/models'

const CommonTopicModule = getModule(CommonTopic, store)

@Component
export default class ListTopic extends Vue {
  @Prop() private topics!: TopicCompactViewModel[]
  @Prop() private startLoading!: boolean
  @Prop() private disableCheckbox?: boolean
  @Prop() private forGroupTop?: boolean
  @Prop() private isFromHome?: boolean
  @Prop() private isHomeCommon?: boolean
  @Prop() private groupId?: string
  @Prop() private isDraft?: boolean
  private isCommonTopic = !this.$route.params.groupId
  private noAvatar = require('@/assets/images/avatar-default.png')
  private noGroupAvatar = require('@/assets/images/group/avatar_default.png')
  private commonTopicAvatar = require('@/assets/images/logo-cs.png')

  /**
   * Handle checkbox / checkAll
   */
  handleCheckItem() {
    if (this.topics.every(item => item.checked)) {
      this.$emit('addCheckAll')
    } else {
      this.$emit('removeCheckAll')
    }
    this.$emit(
      'selectAnyItem',
      this.topics.some(item => item.checked)
    )
  }

  /**
   * Handle click 'mark as read' button
   */
  handleMarkRead(index: number) {
    this.$blockui.show()
    if ((this.isCommonTopic && !this.isFromHome) || this.isHomeCommon) {
      CommonTopicService.markAsCommonTopicRead(this.topics[index].id)
        .then(res => {
          if (res.status === 200) {
            this.topics[index].is_read = true
            this.$emit('commonTopicReaded', this.topics[index].id)
            this.$emit('subtractReadCount')
            getCommonTopicCount()
            //handle rebind unred count
            CommonTopicModule.SET_RELOAD_UNREAD(true)
          }
        })
        .catch(err => {
          // this.$bvModal.show('modal-error-list-topic')
        })
        .finally(() => this.$blockui.hide())
    } else {
      GroupTopicService.markAsRead(
        this.isFromHome ? String(this.groupId) : this.$route.params.groupId,
        this.topics[index].id
      )
        .then(res => {
          if (res.status === 200) {
            this.topics[index].is_read = true
            this.$emit('subtractReadCount')
            //handle rebind unred count
            CommonTopicModule.SET_RELOAD_UNREAD(true)
          }
        })
        .catch(err => {
          // this.$bvModal.show('modal-error-list-topic')
        })
        .finally(() => {
          this.$blockui.hide()
        })
    }
  }

  /**
   * Handle jumping to topic detail page
   */
  jumpToTopicDetailPage(index: number, push?: boolean) {
    const topicId = this.topics[index].id
    let route: any
    if ((this.isCommonTopic && !this.isFromHome) || this.isHomeCommon) {
      route = {
        name: 'common-topic-new-feed-topic-detail',
        params: { topicId: String(topicId), from: this.$route.path }
      }
    } else {
      route = {
        name: 'group-new-feed-topic-detail',
        params: {
          topicId: String(topicId),
          groupId: this.isFromHome
            ? String(this.groupId)
            : this.$route.params.groupId,
          folder: this.$route.query.folder
            ? String(this.$route.query.folder)
            : '',
          from: this.$route.path
        }
      }
    }
    if (push) {
      this.$router.push(route)
    } else {
      return route
    }
  }

  /**
   * Helper format date
   */
  formatDateWithDays(date: string) {
    return FormatDate.formatDateWithDays(date)
  }

  handleLongText(topic: TopicCompactViewModel) {
    // topic.updater.name is from group TOP / topic.updater is from group Topic TOP
    let text = this.hanldeUserStatus(
      topic.updater.name ? topic.updater.name : topic.updater,
      topic.user_active_status
    )
    const preview = PreviewText.covertToPreviewText(text, 20)
    return {
      name: text,
      text: preview.text
    }
  }

  truncateText(text: string) {
    return _.truncate(text, {
      length: 50,
      separator: ' '
    })
  }

  hanldeUserStatus(name: string, status: EUserStatus) {
    if (status === EUserStatus.LEAVE_GROUP_TAG)
      return name + this.$t('common.suffix.leave_group')
    if (status === EUserStatus.LEAVE_SYSTEM)
      return this.$t('common.suffix.leave_system') as string
    return name
  }
}
